import React from "react";

function ThankYouScreen(){
    return(
        <div>
            Gracias
        </div>
    )
}

export default ThankYouScreen;