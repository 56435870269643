import React from "react";

function PrivacyPolicyScreen(){
    return(
        <div>
            PrivacyPolicyScreen
        </div>
    )
}

export default PrivacyPolicyScreen;